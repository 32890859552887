import React from "react";
import Layout from "../components/Layout";
import SEO from '../components/seo';
import FormContato from '../components/Forms/FormContato';
import Navbar from "../components/Navbar";

const IndexPage = () => {
  return (
    <Layout>
      <SEO 
          title='Quem somos?'
          description='Acompanhe e desenvolva as COMPETÊNCIAS e HABILIDADES que seus colaboradores necessitam para potencializar os resultados de sua empresa.'
      />
      <div className="w-full lg:w-4/5 px-8 lg:px-0">
        <Navbar/>
        <div className="w-full flex min-h-screen my-10">
                <div className="flex flex-col w-4/5 text-gray-800">
                    <div className="flex flex-col space-y-2 mb-8 font-medium">
                        <div className="text-3xl">
                            Quem somos?
                        </div>
                    </div>
                    <div className="text-2xl w-4/5">
                        A <span class="font-semibold">SHELFIN</span> é uma plataforma focada na gestão do conhecimento dentro da sua empresa utilizando meios modernizados e intuitivos, com ferramentas focadas em disponibilizar ao líder e ao colaborador total visão das habilidades e competências necessárias de seu cargo mostrando quais os passos necessários para se adaptar às necessidades da sua empresa.
                    </div>
                </div>
            </div>
      </div>
    </Layout>
  )
}

export default IndexPage
